var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_invoice_prepayment_credit_memo") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form, layout: "vertical" } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.invoiceType.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.invoiceType.decorator,
                                  expression: "formRules.invoiceType.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.invoiceType.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.invoiceType.placeholder
                                ),
                                disabled: _vm.disabled.invoiceType,
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption,
                                loading: _vm.loading.invoiceType,
                                "allow-clear": true
                              }
                            },
                            _vm._l(_vm.dataListInvoiceType, function(
                              data,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(data.value || "-") + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(data.value || "-") + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.branch.label) }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.branch.decorator,
                                  expression: "formRules.branch.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.branch.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.branch.placeholder
                                ),
                                "show-search": true,
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption,
                                loading: _vm.loading.branch,
                                "allow-clear": true
                              },
                              on: {
                                search: function(value) {
                                  return _vm.getListOfWarehouseBranch(
                                    "name~*" + value + "*",
                                    value
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.dataListBranch, function(data) {
                              return _c(
                                "a-select-option",
                                { key: data.id, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(data.name || "-") + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(data.name || "-") + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.customerName.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.customerName.decorator,
                                  expression: "formRules.customerName.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.customerName.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.customerName.placeholder
                                ),
                                "show-search": true,
                                "allow-clear": true,
                                loading: _vm.loading.customer,
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption
                              },
                              on: {
                                search: function(value) {
                                  return _vm.getCustomer(
                                    "_AND_firstName~*" +
                                      value +
                                      "*_OR_lastName~*" +
                                      value +
                                      "*",
                                    value
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.dataListCustomer, function(data) {
                              return _c(
                                "a-select-option",
                                { key: data.id, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(data.fullName || "-") +
                                            " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(data.fullName || "-") + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.invoiceDateFrom.label)
                          }
                        },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.invoiceDateFrom.decorator,
                                expression:
                                  "formRules.invoiceDateFrom.decorator"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              name: _vm.formRules.invoiceDateFrom.name,
                              placeholder: _vm.$t(
                                _vm.formRules.invoiceDateFrom.placeholder
                              ),
                              format: _vm.DEFAULT_DATE_FORMAT
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.invoiceDateTo.label)
                          }
                        },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.invoiceDateTo.decorator,
                                expression: "formRules.invoiceDateTo.decorator"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              name: _vm.formRules.invoiceDateTo.name,
                              placeholder: _vm.$t(
                                _vm.formRules.invoiceDateTo.placeholder
                              ),
                              format: _vm.DEFAULT_DATE_FORMAT
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.invoiceNumber.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.invoiceNumber.decorator,
                                  expression:
                                    "formRules.invoiceNumber.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.invoiceNumber.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.invoiceNumber.placeholder
                                ),
                                "allow-clear": true,
                                "show-search": true,
                                "filter-option": _vm.filterOption
                              },
                              on: {
                                search: function(value) {
                                  return _vm.getListInvoiceNumber(
                                    "documentNumber~*" + value + "*",
                                    value,
                                    false
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.dataListInvoiceNumber, function(data) {
                              return _c(
                                "a-select-option",
                                {
                                  key: data.invoicePrepaymentNumber,
                                  attrs: { value: data.invoicePrepaymentNumber }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              data.invoicePrepaymentNumber ||
                                                "-"
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            data.invoicePrepaymentNumber || "-"
                                          ) +
                                          " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.invoiceStatus.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.invoiceStatus.decorator,
                                  expression:
                                    "formRules.invoiceStatus.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.invoiceStatus.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.invoiceStatus.placeholder
                                ),
                                "allow-clear": true,
                                "filter-option": _vm.filterOption
                              }
                            },
                            _vm._l(_vm.dataListInvoiceStatus, function(data) {
                              return _c(
                                "a-select-option",
                                { key: data.id, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + data.name) + " "
                                        )
                                      ]),
                                      _vm._v(" " + _vm._s("" + data.name) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-button", { on: { click: _vm.clear } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                          ]),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.find,
                                icon: "search"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.findData(false)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, align: "end" } },
                    [
                      _vm.hasPrivilegeInvoicePrepaymentCreate
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary", icon: "plus" },
                              on: { click: _vm.createNew }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("TableCustom", {
                    attrs: {
                      idtable: "table1",
                      "data-source": _vm.dataSource,
                      columns: _vm.columnsTable,
                      "on-select-change": null,
                      "selected-row-keys": null,
                      scroll: { x: "calc(700px + 50%)", y: 500 },
                      paginationcustom: true,
                      "default-pagination": false,
                      loading: _vm.loading.find
                    },
                    on: {
                      "on-columnClicked": _vm.handleClickColumn,
                      "on-edit": _vm.handleClickEdit
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.totalElements,
                      "page-size-set": _vm.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(_vm.totalElements) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }