























































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Messages } from "@/models/enums/messages.enum";
import { ResponsePagination } from "@/models/interface/common.interface";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@service/contact.service";
import { ContactData } from "@interface/contact.interface";
import { masterServices } from "@/services/master.service";
import { invoicePrepayment } from "@service/invoice-prepayment.service";
import { InvoicePrepaymentData } from "@interface/invoice-prepayment.interface";
import { currencyFormat } from "@/validator/globalvalidator";
import { warehouseBranchService } from "@/services-v2/warehouse-branch.service";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
interface DataListInvoiceStatus {
  id: string;
  name: string;
}
interface DefaultDropdown {
  id: string;
  name: string;
  idCardNumber?: "";
}
@Component
export default class ListInvoicePrepayment extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  moment = moment;
  page = 1 as number;
  limit = DEFAULT_PAGE_SIZE as number;
  dataListBranch = [] as DataWarehouseBranch[];
  totalElements = 0 as number;
  dataSource = [] as InvoicePrepaymentData[];
  dataListCustomer = [] as ContactData[];
  dataListInvoiceStatus = [] as DataListInvoiceStatus[];
  dataListInvoiceNumber = [] as InvoicePrepaymentData[];
  dataListInvoiceType = [] as DefaultDropdown[];

  loading = {
    find: false as boolean,
    download: false as boolean,
    branch: false as boolean,
    customer: false as boolean,
    invoiceStatus: false as boolean,
    invoiceNumber: false as boolean,
    invoiceType:false as boolean
  };
  disabled = {
    download: true as boolean,
  };
  columnsTable = [
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoicePrepaymentNumber",
      key: "invoicePrepaymentNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDateTo",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
     {
      title: this.$t("lbl_invoice_type"),
      dataIndex: "invoiceType",
      key: "invoiceType",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_invoice_amount"),
      dataIndex: "amount",
      key: "invoiceAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNumber",
      key: "journalNumber",
      width: 150,
      scopedSlots: { customRender: "isJournal" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "invoicePrepaymentStatus",
      key: "status",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["update"],
      width: 120,
      align: "center",
      fixed: "right",
    }
  ] as any;
  form!: any; 
  formRules = {
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_choose",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_choose",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceType: {
      label: "lbl_invoice_type",
      name: "invoiceType",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceType",
        {
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceStatus: {
      label: "lbl_invoice_status",
      name: "invoiceStatus",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceStatus",
        {
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceDateFrom: {
      label: "lbl_invoice_date_from",
      name: "invoiceDateFrom",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceDateFrom",
        {
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceDateTo: {
      label: "lbl_invoice_date_to",
      name: "invoiceDateTo",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceDateTo",
        {
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "invoiceNumber",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceNumber",
        {
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
  };

  get hasPrivilegeInvoicePrepaymentCreate(): boolean {
    return this.$store.getters["authStore/GET_USER_PRIVILEGES"].find(x => x.key === "invoice-prepayment" && x.privilege.create && x.privilege.update);
  }

  getListOfMasterTypeInvoiceType() {
    let params = {
      name: "INVOICE_AR_TYPE",
    } as RequestQueryParamsModel;
    return masterServices.listMaster(params).then((res) => {
      this.dataListInvoiceType = res.filter((e) => e.value != "Invoice AR");
    });
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  handleClickEdit({ data }): void {
    this.$router.push({
      name: "accountreceivables.invoices.prepayment.edit",
      params: { id: data.id }
    });
  }
  createNew(): void {
    this.$router.push("/account-receivables/invoices/invoiceprepayment/create");
  }
  clear(): void {
    this.form.resetFields();
  }
  handleClickColumn(record, col): void {
    if (col.column === "journalNumber" && record.journalId) {
      this.$router.push({ name: "generaljournal.detail", params: { id: record.journalId } });
    }
  }
  @Watch("dataSource")
  public changeDataSource() {
    if (this.dataSource.length > 0) this.disabled.download = false;
  }
  getCustomer(query, value: string): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: DEFAULT_PAGE_SIZE,
      search: "customer~true_AND_active~true",
      sorts: "firstName:asc"
    };
    if (query && value) {
      params.search += query;
    }
    this.loadingCustomer = true;
    contactServices.listContactData(params)
      .then((data) => {
        this.dataListCustomer = data.data;
      })
      .finally(() => (this.loadingCustomer = false));
  }
  created(): void {
    this.form = this.$form.createForm(this, { name: "formInvoicePrepayment" });
    this.getCustomer("", "");
    this.getListOfWarehouseBranch("", "");
    this.getListInvoiceNumber("", "", true);
    this.getListOfMasterTypeInvoiceStatus();
    this.getListOfMasterTypeInvoiceType();
  }
  getListInvoiceNumber(query, value, find) {
    let params: RequestQueryParamsModel = {
      limit: DEFAULT_PAGE_SIZE,
      page: 0,
      sorts: "createdDate:desc",
    };
    if (find) {
      params = query;
    } else {
      if (query && value) params.search = query;
    }
    this.loading.invoiceNumber = true;
    this.loading.find = true;
    return invoicePrepayment
      .getListInvoicePrepayment(params)
      .then((res) => {
        this.dataListInvoiceNumber = res.data;
        if (find) {
          res.data.forEach((item) => {
            item["key"] = item.id;
            item.amount = currencyFormat(item.amount);
            item.invoiceDate = moment(item.invoiceDate).format(DEFAULT_DATE_FORMAT);
          });
          this.dataSource = res.data;
          this.totalElements = res.totalElements;
        }
      })
      .finally(() => {
        this.loading.find = false;
        this.loading.invoiceNumber = false;
      });
  }
  assignSearch(key, value, operator): string {
    if (key === "branch" && value)
      return operator + `branch.secureId~${value}`;
          else if (key === "invoiceType" && value)
      return operator + `invoiceType~${trimSpaceToUnderscore(value)}`;
    else if (key === "customerName" && value)
      return operator + `customer.secureId~${value}`;
    else if (key === "invoiceDateFrom" && value)
      return (
        operator +
        `invoiceDate>=${moment(value)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .format()}`
      );
    else if (key === "invoiceDateTo" && value)
      return (
        operator +
        `invoiceDate<=${moment(value)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format()}`
      );
    else if (key === "invoiceNumber" && value)
      return operator + `invoicePrepaymentNumber~*${value}*`;
    else if (key === "invoiceStatus" && value)
      return operator + `invoicePrepaymentStatus~${trimSpaceToUnderscore(value)}`;
    else if (key === "invoiceType" && value)
      return operator + "";
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach((key) => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  getListOfMasterTypeInvoiceStatus() {
    let params = {
      name: "INVOICE_PREPAYMENT_STATUS",
    } as RequestQueryParamsModel;
    return masterServices.listMaster(params).then((res) => {
      const dataList = res.map((item) => {
        return {
          id: item.value,
          name: item.value,
        };
      });
      this.dataListInvoiceStatus = dataList;
    });
  }
  getListOfWarehouseBranch(query, value) {
    let params = {
      limit: DEFAULT_PAGE_SIZE,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (query && value) params.search = query;
    this.loading.branch = true;
    return warehouseBranchService
      .listWarehouseBranch(params)
      .then((res) => {
        this.dataListBranch = res.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  async findData(pagination): Promise<void> {
    try {
      this.form.validateFields((err, res) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: pagination ? this.page - 1 : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        params.search = this.dynamicSearch(res);
        this.getListInvoiceNumber(params, "find", true);
      });
    } catch (error) {
      this.$message.error(Messages.PROCESS_FAIL);
    }
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 1;
    this.findData(true);
  }

  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page;
    this.findData(true);
  }
}
